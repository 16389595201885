import React from "react"
import { Link } from "gatsby"

const CategoryCard = ({ frontmatter }) => {
  const tags = frontmatter.usingTags.map(item => {
    return (
      <span
        key={item}
        className="inline-block bg-grey-lighter rounded-full px-2 py-1 text-sm font-semibold text-grey-darker mr-2 text-center "
      >
        {item}
      </span>
    )
  })

  return (
    <Link
      to={frontmatter.premium ? '#premium' : frontmatter.path}
      className="w-full md:w-1/3 p-6 flex flex-col flex-shrink no-underline"
    >
      <div className="max-w-sm rounded overflow-hidden shadow-lg" data-premium={frontmatter.premium}>
        <img
          className="w-full p-8"
          src={frontmatter.image.publicURL}
          alt={`${frontmatter.title}`}
        />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2 text-okto-dark">
            {" "}
            {frontmatter.title}<br/>
          </div>
          <p className="text-grey-darker text-base">{frontmatter.excerpt}</p>
        </div>
        <div className="px-6 py-4 flex flex-row flex-wrap items-center content-start justify-start">
          {tags}
        </div>
      </div>
    </Link>
  )
}

export default CategoryCard
